function validateContactInfo(form, data) {
    data.FullName = $.trim(data.FullName || '');
    if (!data.FullName || data.FullName.length === 0) {
        Notification.Tooltip.Show("Please enter your name", form.find('[name="FullName"]'));
        return false;
    }

    var firstLast = data.FullName.split(" ");
    if (firstLast.length <= 1) {
        Notification.Tooltip.Show("Please enter your full name", form.find('[name="FullName"]'));
        return false;
    }

    for (var i = 0; i < firstLast.length; i++) {
        if (firstLast[i].length == 0) continue;

        firstLast[i] = firstLast[i].replace(/[^a-zA-Z]+/g, '');
        if (firstLast[i].length == 0) {
            Notification.Tooltip.Show("Please enter a valid name", form.find('[name="FullName"]'));
            return false;
        }
    }

    data.Email = $.trim(data.Email || '');
    if (!data.Email || !IsEmail(data.Email)) {
        Notification.Tooltip.Show("Please enter a valid email", form.find('[name="Email"]'));
        return false;
    }

    data.Phone = $.trim(data.Phone || '');
    if (!data.Phone || data.Phone.length < 10) {
        Notification.Tooltip.Show("Please enter a valid phone number", form.find('[name="Phone"]'));
        return false;
    }

    return data;
}

function IsEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9_-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
};

$('.js-inquiry-form').on('submit', function (e) {
    e.preventDefault();

    var $form = $(this);
    var data = $form.serializeObject();

    var searchTermsAgree = $form.find('.js-searchtermsagree');
    if (searchTermsAgree.length === 1 && !searchTermsAgree.prop('checked')) {
        Notification.Tooltip.Show("Please accept the terms and conditions", $form.find('.js-searchtermsagree'));
        return;
    }

    var $submit = $form.find('input[type="submit"]');
    if ($submit.hasClass('loading')) {
        return false;
    }
    $submit.addClass('loading');

    // Validate contact info; returns updated data if valid; false otherwise
    data = validateContactInfo($form, data);
    if (!data) return;

    var streetElement = $form.find('[name="Street"]');
    if (streetElement.length > 0 && !streetElement.val()) {
        Notification.Tooltip.Show("Please enter a street address", streetElement);
        return;
    }

    var cityElement = $form.find('[name="City"]');
    if (cityElement.length > 0 && !cityElement.val()) {
        Notification.Tooltip.Show("Please enter a city", cityElement);
        return;
    }

    var zipElement = $form.find('[name="Zip"]');
    if (zipElement.length > 0 && !zipElement.val()) {
        Notification.Tooltip.Show("Please enter a street address", zipElement);
        return;
    }

    data.Message = $.trim(data.Message || '');
    var crLf = '\r\n';
    if (data.BuyerLocation || data.BuyerMinPrice || data.BuyerMaxPrice || data.BuyerBeds) {
        data.Message += crLf + crLf +
            'Buyer Info' + crLf +
            'Location: ' + (data.BuyerLocation || 'n/a') + crLf +
            'Price Range: ' + (data.BuyerMinPrice || '$0') + ' - ' + (data.BuyerMaxPrice || 'n/a') + crLf +
            'Beds: ' + (data.BuyerBeds || 'Any');
        if (data.BuyerPropertyTypes) {
            data.Message += crLf + 'Property Types: ' + data.BuyerPropertyTypes.replace(/,/g, ', ');
        }
    }
    if (data.PropertyAddress || data.PropertyStyle || data.PropertySqFt || data.PropertyAge || data.PropertyBeds | data.PropertyBaths || data.PropertyFeatures || data.SellerReason || data.SellerTimeframe) {
        data.Message += crLf + crLf +
            'Seller Info' + crLf +
            'Current Address: ' + (data.PropertyAddress || 'n/a') + crLf +
            'Property Style: ' + (data.PropertyStyle || 'n/a') + crLf +
            'Approx SqFt: ' + (data.PropertySqFt || 'n/a') + crLf +
            'Property Age: ' + (data.PropertyAge || 'n/a') + crLf +
            'Beds: ' + (data.PropertyBeds || 'n/a') + crLf +
            'Baths: ' + (data.PropertyBaths || 'n/a') + crLf +
            'Special Features: ' + (data.PropertyFeatures || 'n/a') + crLf +
            'Reason: ' + (data.SellerReason || 'n/a') + crLf +
            'Timeframe: ' + (data.SellerTimeframe || 'n/a');
    }
    if (data.ReferralFullName || data.ReferralEmail || data.ReferralPhone) {
        data.Message += crLf + crLf +
            'Referral Info' + crLf +
            'Referral Name: ' + (data.ReferralFullName || 'n/a') + crLf +
            'Referral Email: ' + (data.ReferralEmail || 'n/a') + crLf +
            'Referral Phone: ' + (data.ReferralPhone || 'n/a');
    }
    if (data.LeadType == 'Recruit' || data.ResumeUrl) {
        data.Message += crLf + crLf +
            'Resume: ' + (data.ResumeUrl || 'n/a')
    }
    if (data.Street) {
        data.Message += crLf + crLf +
            'Street: ' + (data.Street || 'n/a') + crLf +
            'City: ' + (data.City || 'n/a') + crLf +
            'State: ' + (data.State || 'n/a') + crLf +
            'Zip: ' + (data.Zip || 'n/a')
    }

    data.Message = $.trim(data.Message);

    var loadingModal = Modals.Loading('').Open();

    CINC.Api.Account('inquiry')
        .post(data)
        .success(function (result) {
            $('.js-inquiry-formwrap').hide();
            $('.js-inquiry-success').show();

            if (!CINC.User) {
                $('body').append(CINC.Site.ConversionTrackingScripts);

                if (typeof ga !== 'undefined' && ga !== null) {
                }

                if (typeof mixpanel !== 'undefined') {
                    mixpanel.track('User Registration Successful');
                }
            }

            if ($('.js-inquiry-noreload').length == 0 && !CINC.User) {
                window.location.reload(true);
            }
            else {
                loadingModal.Close();
            }
        })
        .fail(function (f) {
            loadingModal.Close();
            if (f.responseJSON && f.responseJSON.message && f.responseJSON.message == "Email already in use") {
                var inquiryForm = $('.js-inquiry-form');
                PromptForLogin({ email: inquiryForm.find('[name=Email]').val() }, true);
            }
            else {
                Notification.Banner.Error(f.responseText, "Error submitting form.");
            }
            $submit.removeClass('loading');
        });

    return false;
});
