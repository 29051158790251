CINC = (typeof CINC === 'undefined' ? {} : CINC);

(function (CINC) {

    CINC.AjaxRequests = {};

    CINC.Api = {
        Root: function (subRoute, unique) { return new AjaxApi('/api', subRoute, unique); },
        CDN: {
            Static: function (subRoute) { return new AjaxApi('//s-static.cinccdn.com/', subRoute, false); },
            Uni: function (subRoute) { return new AjaxApi("//uni.cinccdn.com/", subRoute, false); }
        },
        Account: function (subRoute) { return new AjaxApi('/api/account', subRoute, false); },
        AccountSocialSign: function (subRoute) { return new AjaxApi('/api/account/social', subRoute, false); },
        Become: function (unique) { return new AjaxApi('/api/account/become', '', unique); },
        Photo: function (subRoute) { return new AjaxApi('/api/photo', subRoute, false) },
        ConsumerAccount: function (subRoute) { return new AjaxApi('/api/consumer/account', subRoute, false); },
        ContactInfo: {
            Email: function (email) { return new AjaxApi('/api/contactinfo/validate/email/' + email, '', false) },
            Phone: function (phone) { return new AjaxApi('/api/contactinfo/validate/phone/' + phone, '', false) }
        },
        Details: function (pdid, unique) { return new AjaxApi('/api/details/' + pdid, '', unique); },
        EmailIntegration: function (subRoute) { return new AjaxApi("/api/dashboard/emailintegration", subRoute); },
        Guide: function (id, unique) { return new AjaxApi('/api/guide/' + id, '', unique); },
        LegalAgreement: function () { return new AjaxApi('/api/lead/legal/agreed', '', false); },
        Organizer: {
            Root: function (subRoute) { return new AjaxApi('/api/organizer', subRoute, true); },
            Headers: function () { return new AjaxApi('/api/organizer/headers', '', true); },
            Focus: function (organizerdid) { return new AjaxApi('/api/organizer/focus', organizerdid, true); },
            Share: function (subRoute) { return new AjaxApi('/api/organizer/share', subRoute, false); },
            Schedule: function (subRoute) { return new AjaxApi('/api/organizer/schedule', subRoute, false); },
            Favorite: function (subRoute) { return new AjaxApi('/api/organizer/favorite', subRoute, false); },
            Opinion: function (subRoute) { return new AjaxApi('/api/organizer/opinion', subRoute, false); },
            Note: function (subRoute) { return new AjaxApi('/api/organizer/note', subRoute, false); },
            Searches: function (subRoute) { return new AjaxApi('/api/organizer/searches', subRoute, false); },
            Team: function (subRoute) { return new AjaxApi('/api/organizer/team', subRoute, false); },
            Ajax: function (subRoute) { return new AjaxApi('/ajax/organizer', subRoute, true); }
        },
        School: function (id, unique) { return new AjaxApi('/api/school', id, unique) },
        Search: {
            // for Autocomplete searches, trim whitespace & periods from word and suffix '/' to allow Web API to correct handle text with periods
            Autocomplete: function (inputName, word, unique) { return new AjaxApi('/api/search/autocomplete/' + inputName, word.replace(/^[.\s]+|[.\s]+$/gm,'') + '/', unique); }, 
            AutocompleteHtml: function (inputName, word, unique) { return new AjaxApi('/ajax/search/autocomplete/' + inputName, word.replace(/^[.\s]+|[.\s]+$/gm,'') + '/', unique); },
            OptionsHtml: function (inputName, unique) { return new AjaxApi('/ajax/search/options/' + inputName, unique); },
            Default: function (unique) { return new AjaxApi("/api/search", '', unique); },
            Html: function (unique) { return new AjaxApi('/ajax/search', '', unique); },
            Quick: function (unique) { return new AjaxApi('/ajax/search/quick', '', unique); },
            Map: function (unique) { return new AjaxApi('/ajax/search/map', '', unique); },
            PDIDs: function () { return new AjaxApi('/api/search/pdids', '', false); },
            Result: function (pdid, unique) { return new AjaxApi('/ajax/search/result', pdid, unique); },
            Split: function (unique) { return new AjaxApi('/ajax/search/split', '', unique); },
            Text: function (unique) { return new AjaxApi('/api/dashboard/propertysearch/fts', '', unique); },
            SavePdq: function (unique) { return new AjaxApi('/api/search/savepdq', '', unique); }
        },
        Style: {
            ColorPalette: function(unique) { return new AjaxApi('api/castle/styles/colorpalette', '', unique) },
            FontPack: function (unique) { return new AjaxApi('api/castle/styles/fontpack', '', unique) },
            HomeCover: function (unique) { return new AjaxApi('api/castle/styles/homecover', '', unique) }
        },
        Dashboard: {},
        BackOffice: {
            Search: function (unique) { return new AjaxApi('/api/backoffice/backoffice/search', '', unique); },
            FindMembers: function (unique) { return new AjaxApi('/api/backoffice/backoffice/findmembers', '', unique); },
            Ping: function () { return new AjaxApi('/api/backoffice/backoffice/ping', '', false); },
            LaunchStats: function (unique) { return new AjaxApi('/api/backoffice/backoffice/launchstats', '', unique); },
            SetupStats: function (unique) { return new AjaxApi('/api/backoffice/backoffice/setupstats', '', unique); },
            NPSScores: function (unique) { return new AjaxApi('/api/backoffice/backoffice/npsscores', '', unique); },
            ChurnStats: function (unique) { return new AjaxApi('/api/backoffice/backoffice/churnstats', '', unique); },
            KensingtonElement: function (unique) { return new AjaxApi('/api/backoffice/backoffice/kensingtonelement', '', unique); },
            SyncLeads: function (unique) { return new AjaxApi('/api/backoffice/backoffice/syncleads', '', unique); },
            SyncContacts: function (unique) { return new AjaxApi('/api/backoffice/backoffice/synccontacts', '', unique); },
            SyncAccounts: function (unique) { return new AjaxApi('/api/backoffice/backoffice/syncaccounts', '', unique); },
            SyncDomains: function (unique) { return new AjaxApi('/api/backoffice/backoffice/syncdomains', '', unique); },
            SyncLeadNotes: function (unique) { return new AjaxApi('/api/backoffice/backoffice/syncleadnotes', '', unique); },
            SyncSiteUsers: function (unique) { return new AjaxApi('/api/backoffice/backoffice/syncsiteusers', '', unique); },
            Churn: {
                MinSecLevel: function (unique) { return new AjaxApi('/api/backoffice/churn/minseclevel', '', unique); },
                OffboardingDetails: function () { return new AjaxApi('/api/backoffice/churn/offboardingdetails', '', false); },
                OffboardingRequest: function () { return new AjaxApi('/api/backoffice/churn/offboardingrequest', '', false); },
                QuitRequest: function (mdid, unique) { return new AjaxApi('/api/backoffice/churn/quitrequest/' + mdid, '', unique); },
                QuitReason: function (mdid, unique) { return new AjaxApi('/api/backoffice/churn/quitreason/' + mdid, '', unique); },
                PlatformLocks: function (unique) { return new AjaxApi('/api/backoffice/churn/platformlock', '', unique); },
                PlatformSaveStatus: function (unique) { return new AjaxApi('/api/backoffice/churn/platformsavestatus', '', unique); },
                AINCSaveStatus: function (unique) { return new AjaxApi('/api/backoffice/churn/aincsavestatus', '', unique); },
                PauseMarketing: function (unique) { return new AjaxApi('/api/backoffice/churn/pausemarketing', '', unique); },
                RestoreDomain: function (unique) { return new AjaxApi('/api/backoffice/churn/restoredomain', '', unique); }
            },
        },
        Chat: {
            SendMessage: function (unique) { return new AjaxApi('/api/dashboard/chat/sendmessage'); },
            MarkMessageRead: function (unique) { return new AjaxApi('/api/dashboard/chat/readmessage'); },
            GetMessagesForUsers: function (unique) { return new AjaxApi('/api/dashboard/chat/getchat'); },
        },
        AWSPushNotification: {
            Preview: function (unique) { return new AjaxApi('/api/awspushnotification/preview'); },
            SendPMQMessage: function (unique) { return new AjaxApi('/api/awspushnotification/sendpmqmessage'); },
            SendCustomMessage: function (unique) { return new AjaxApi('/api/awspushnotification/sendcustommessage'); },
        }
    };

    if (CINC.Site && (CINC.Site.DomainName === 'mycinc.com' || CINC.Site.DomainName === 'cincghq.com')) {
        $.extend(CINC.Api.BackOffice, {
            Accounts: {
                MergeImpact: function (unique) { return new AjaxApi('/api/backoffice/accounts/mergeimpact', '', unique); },
                Merge: function (unique) { return new AjaxApi('/api/backoffice/accounts/merge', '', unique); },
                Note: function (unique) { return new AjaxApi('/api/backoffice/accounts/note', '', unique); },
                Link: function (unique) { return new AjaxApi('/api/backoffice/accounts/link', '', unique); },
                VcomRunDay: function (unique) { return new AjaxApi('/api/backoffice/accounts/vcomrunday', '', unique); }
            },
            PPC: {
                UpdatePPCName: function (unique) { return new AjaxApi('/api/backoffice/ppc/updateppcname', '', unique); },
                AutoCompleteGeo: function (unique) { return new AjaxApi('/api/backoffice/ppc/autocompletegeo', '', unique); },
                BatchJobStatus: function (unique) { return new AjaxApi('/api/backoffice/ppc/batchjobstatus', '', unique); },
            },
            Invoice: {
                CreditCard: function (unique) { return new AjaxApi('/api/backoffice/invoice/invoicecreditcard', '', unique); },
                Payment: function (unique) { return new AjaxApi('/api/backoffice/invoice/payment', '', unique); },
                Void: function (unique) { return new AjaxApi('/api/backoffice/invoice/void', '', unique); },
                Dispute: function (unique) { return new AjaxApi('/api/backoffice/invoice/dispute', '', unique); },
                Check: function (unique, transid) { return new AjaxApi('/api/backoffice/invoice/check' + (transid ? '/' + transid : ''), '', unique); },
                Credit: function (unique) { return new AjaxApi('/api/backoffice/invoice/credit', '', unique); },
                ApplyBalance: function (unique, invoiceid) { return new AjaxApi('/api/backoffice/invoice/applybalance/' + invoiceid, '', unique); },
                SaveInvoiceNotes: function (unique) { return new AjaxApi('/api/backoffice/invoice/saveinvoicenotes', '', unique); },
                SaveUnchargeNotes: function (unique) { return new AjaxApi('/api/backoffice/invoice/saveunchargenotes', '', unique); },
                SaveCustomMessage: function (unique) { return new AjaxApi('/api/backoffice/invoice/savecustommessage', '', unique); },
                ReconcileInvoice: function (unique) { return new AjaxApi('/api/backoffice/invoice/reconcileinvoice', '', unique); },
                EmailPreview: function (unique) { return new AjaxApi('/api/backoffice/invoice/emailpreview', '', unique); },
                SendEmail: function (unique) { return new AjaxApi('/api/backoffice/invoice/sendemail', '', unique); },
                ReSendEmail: function (unique) { return new AjaxApi('/api/backoffice/invoice/resendemail', '', unique); },
                SendNextMonthEmail: function (unique) { return new AjaxApi('/api/backoffice/invoice/sendnextmonthemail', '', unique); },
                CorrectInvoice: function (unique) { return new AjaxApi('/api/backoffice/invoice/correctinvoice', '', unique); },
                QueueItem: function (rowid, unique) { return new AjaxApi('/api/backoffice/invoice/queueitem/' + rowid, '', unique); },
                QueueItems: function (invoiceid, unique) { return new AjaxApi('/api/backoffice/invoice/queueitems/' + invoiceid, '', unique); },
                Reassign: function (unique) { return new AjaxApi('/api/backoffice/invoice/reassign', '', unique); },
                Writeoff: function (unique, transid) { return new AjaxApi('/api/backoffice/invoice/writeoff' + (transid ? '/' + transid : ''), '', unique); }
                //Check: function (unique, transid) { return new AjaxApi('/api/backoffice/invoice/check/' + transid, '', unique); },
            },
            Churn: {
                MinSecLevel: function (unique) { return new AjaxApi('/api/backoffice/churn/minseclevel', '', unique); },
                OffboardingDetails: function () { return new AjaxApi('/api/backoffice/churn/offboardingdetails', '', false); },
                OffboardingRequest: function () { return new AjaxApi('/api/backoffice/churn/offboardingrequest', '', false); },
                QuitRequest: function (mdid, unique) { return new AjaxApi('/api/backoffice/churn/quitrequest/' + mdid, '', unique); },
                QuitReason: function (mdid, unique) { return new AjaxApi('/api/backoffice/churn/quitreason/' + mdid, '', unique); },
                PlatformLocks: function (unique) { return new AjaxApi('/api/backoffice/churn/platformlock', '', unique); },
                PlatformSaveStatus: function (unique) { return new AjaxApi('/api/backoffice/churn/platformsavestatus', '', unique); },
                AINCSaveStatus: function (unique) { return new AjaxApi('/api/backoffice/churn/aincsavestatus', '', unique); },
                PauseMarketing: function (unique) { return new AjaxApi('/api/backoffice/churn/pausemarketing', '', unique); },
                RestoreDomain: function (unique) { return new AjaxApi('/api/backoffice/churn/restoredomain', '', unique); }
            },
            Platform: {
                SiteData: function (unique) { return new AjaxApi('/api/backoffice/platform/sitedata', '', unique); },
                Members: function (unique) { return new AjaxApi('/api/backoffice/platform/members', '', unique); },
                ProductUsages: function (unique) { return new AjaxApi('/api/backoffice/platform/productusages', '', unique); },
                ProductUsage: function (unique) { return new AjaxApi('/api/backoffice/platform/productusage', '', unique); },
                ChangeDomainName: function (unique) { return new AjaxApi('/api/backoffice/platform/changedomainname', '', unique); }
            },
            ClientServices: {
                MLSParticipants: function (mlsid, unique) { return new AjaxApi('/api/backoffice/clientservices/mlsparticipants/' + mlsid, '', unique); },
                ParticipantName: function (unique) { return new AjaxApi('/api/backoffice/clientservices/participantname', '', unique); },
                MLSReportConfig: function (unique) { return new AjaxApi('/api/backoffice/clientservices/mlsreportconfig', '', unique); },
                LeadFlowStats: function (unique) { return new AjaxApi('/api/backoffice/clientservices/leadflowstats', '', unique); },
                NPSByAgent: function (unique) { return new AjaxApi('/api/backoffice/clientservices/npsbyagent', '', unique); },
                MLSList: function (unique) { return new AjaxApi('/api/backoffice/clientservices/mlslist', '', unique); },
                MLSClients: function (mlsid, unique) { return new AjaxApi('/api/backoffice/clientservices/mlsclients/' + mlsid, '', unique); },
                ReassignClients: function (unique) { return new AjaxApi('/api/backoffice/clientservices/reassignclients', '', unique); }
            },
            HR: {
                Employee: function (unique) { return new AjaxApi('/api/backoffice/hr/employee', '', unique); }
            }
        });

        $.extend(CINC.Api, {
            ClientServices: {
                MLSParticipants: function (mlsid, unique) { return new AjaxApi('/api/backoffice/clientservices/mlsparticipants/' + mlsid, '', unique); },
                ParticipantName: function (unique) { return new AjaxApi('/api/backoffice/clientservices/participantname', '', unique); },
                MLSReportConfig: function (unique) { return new AjaxApi('/api/backoffice/clientservices/mlsreportconfig', '', unique); },
                LeadFlowStats: function (unique) { return new AjaxApi('/api/backoffice/clientservices/leadflowstats', '', unique); },
                NPSByAgent: function (unique) { return new AjaxApi('/api/backoffice/clientservices/npsbyagent', '', unique); },
                MLSList: function (unique) { return new AjaxApi('/api/backoffice/clientservices/mlslist', '', unique); },
                MLSClients: function (mlsid, unique) { return new AjaxApi('/api/backoffice/clientservices/mlsclients/' + mlsid, '', unique); }
            },
            Onboarding: {
                SetStatus: function (unique) { return new AjaxApi('/api/backoffice/onboarding/setstatus', '', unique); },
                DNSRecords: function (unique) { return new AjaxApi('/api/backoffice/onboarding/getdnsrecords', '', unique); },
                OnboardingNote: function (unique) { return new AjaxApi('/api/backoffice/onboarding/addonboardingnote', '', unique); },
                AddLead: function (unique) { return new AjaxApi('/api/backoffice/onboarding/addlead', '', unique); },
                GenerateSetupInvoice: function (unique) { return new AjaxApi('/api/backoffice/onboarding/generatesetupinvoice', '', unique); },
                SendMassAgentWelcomeEmail: function (unique) { return new AjaxApi('/api/backoffice/onboarding/sendmassagentwelcomeemail', '', unique); },
                CheckEmail: function (unique) { return new AjaxApi('/api/backoffice/onboarding/checkemail', '', unique); },
                PartnerAccount: function (unique) { return new AjaxApi('/api/backoffice/onboarding/partneraccount', '', unique); },
                CreatePartnerMembers: function (unique) { return new AjaxApi('/api/backoffice/onboarding/createpartnermembers', '', unique); },
                ResetSteps: function (unique) { return new AjaxApi('/api/backoffice/onboarding/resetsteps', '', unique); },
                SelfServiceEmail: function (unique) { return new AjaxApi('/api/backoffice/onboarding/selfserviceemail', '', unique); },
                LaunchSite: function (unique) { return new AjaxApi('/api/backoffice/onboarding/launchsite', '', unique); },
                ChangeDomainNameAndRegister: function (unique) { return new AjaxApi('/api/backoffice/onboarding/changedomainnameandregister', '', unique); }
            },
            Conference: {
                SyncLeads: function (unique) { return new AjaxApi('/api/backoffice/conference/syncleads', '', unique); },
                ChargeAttendees: function (unique) { return new AjaxApi('/api/backoffice/conference/chargeattendees', '', unique); },
                SetAttendeeStatus: function (unique) { return new AjaxApi('/api/backoffice/conference/setattendeestatus', '', unique); },
                AttendeeComp: function (unique) { return new AjaxApi('/api/backoffice/conference/attendeecomp', '', unique); },
                SiteFeatures: function (unique) { return new AjaxApi('/api/backoffice/conference/sitefeatures', '', unique); }
            },
            Keymaster: {
                Subscription: function (unique) { return new AjaxApi('/api/backoffice/keymaster/subscription', '', unique); }
            },
            CreditCard: {
                Disable: function (unique) { return new AjaxApi('/api/backoffice/creditcard/disable', '', unique); },
                Enable: function (unique) { return new AjaxApi('/api/backoffice/creditcard/enable', '', unique); },
                MakePrimary: function (unique) { return new AjaxApi('/api/backoffice/creditcard/makeprimary', '', unique); },
                Card: function (unique) { return new AjaxApi('/api/backoffice/creditcard/card', '', unique); },
                Cards: function (unique) { return new AjaxApi('/api/backoffice/creditcard/cards', '', unique); },
                Copy: function (unique) { return new AjaxApi('/api/backoffice/creditcard/copycard', '', unique); },
                New: function (unique) { return new AjaxApi('/api/backoffice/creditcard/newcard', '', unique); }
            },
            ZenDesk: {
                SyncPlatform: function (unique) { return new AjaxApi('/api/backoffice/zendesk/syncplatform', '', unique); }
            }
        });
    }
    else {
        $.extend(CINC.Api, {
            Onboarding: {
                LaunchSite: function (unique) { return new AjaxApi('/api/backoffice/onboarding/launchsite', '', unique); },
                SendMassAgentWelcomeEmail: function (unique) { return new AjaxApi('/api/backoffice/onboarding/sendmassagentwelcomeemail', '', unique); },
                ChangeDomainNameAndRegister: function (unique) { return new AjaxApi('/api/backoffice/onboarding/changedomainnameandregister', '', unique); }
            }
        });
    }

    var AjaxApi = function (baseRoute, subRoute, unique) {

        this.unique = (unique || false);
        this.baseRoute = baseRoute;
        this.url = baseRoute + '/' + (subRoute || '');
        var isMyCinc = false;
        if (this.unique) {
            if (CINC.AjaxRequests[this.baseRoute]) {
                for (var i = 0; i < CINC.AjaxRequests[this.baseRoute].length; i++) {
                    CINC.AjaxRequests[this.baseRoute][i].abort();
                }
            }
            else {
                CINC.AjaxRequests[this.baseRoute] = [];
            }
        }

        this.useMyCinc = function () {
            if (!CINC.InDev && !CINC.InStaging) {
                this.url = "https://www.mycinc.com" + this.url;
                isMyCinc = true;
            }
        };

        this.handleReq = function (type, data, rawJson) {
            var options = {
                url: this.url,
                type: type,
                data: data,
            };

            if (isMyCinc) {
                options.headers = { 'gkdid': CINC.User.gkdid };
            }

            if (rawJson) {
                $.extend(options, {
                    processData: false,
                    contentType: 'application/json',
                    data: JSON.stringify(data)
                });
            }

            var req = $.ajax(options);
            if (this.unique) {
                CINC.AjaxRequests[this.baseRoute].push(req);
            }

            req.displayFailures = function (title) {
                req.fail(function (err) {
                    if (CINC && CINC.Dashboard && CINC.Dashboard.ShowError) {
                        CINC.Dashboard.ShowError(err, title);
                        return;
                    }

                    console.error(err); // leave this here
                    var text = (typeof err === 'string') ? err : err.responseText || err.statusText;
                    if (err.responseJSON && err.responseJSON.message) text = err.responseJSON.message;
                    Notification.Banner.Error(text, title);
                });

                return req;
            };

            if (this.laddaElement) {
                var el = this.laddaElement;
                if ($(el).is('form')) {
                    el = $(el).find('button[type="submit"]');
                }

                if ($(el).is('button')) {
                    var ladda = Ladda.create(el[0]).start();

                    req.always(function () {
                        ladda.stop();
                    });
                }
            }

            return req;
        };

        this.ladda = function (el) {
            this.laddaElement = el;
            return this;
        };

        this.get = function (data) {
            return this.handleReq('GET', data, false);
        };

        this.post = function (data) {
            return this.handleReq('POST', data, true);
        };

        this.patch = function (data) {
            return this.handleReq('PATCH', data, true);
        };

        this.put = function (data) {
            return this.handleReq('PUT', data, true);
        };

        this.del = function (data) {
            return this.handleReq('DELETE', data, true);
        };

    };

    CINC.AjaxApi = AjaxApi;

})(CINC);